






















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BBadge,
  BLink,
  BFormInput,
  BPagination,
  BTable,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { avatarText } from '@core/utils/filter'
import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'
import AdminEditOrderSidebar from '@/views/admin/orders/AdminEditOrderSidebar.vue'
import { OrderModule } from '@/store/modules/orderModule'

@Component({
  name: 'AdminOrdersList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BSidebar,
    VSelect,

    AdminEditOrderSidebar
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminOrdersList extends mixins(Vue, MixinFunctions) {
  @Action(OrderModule, 'fetchAdminOrders') private fetchAdminOrders!: (payload: ModelPayload) => Promise<Pagination>

  $refs!: { refOrdersTable }

  private fields: object[] = [
    { key: 'user' },
    { key: 'email' },
    { key: 'roles' },
    { key: 'account_status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ]

  private orderPagination: Pagination = defaultPagination()
  private avatarText: any = avatarText
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }

  private async loadOrders () {
    try {
      this.orderPagination = await this.fetchAdminOrders({
        params: this.paramsPayload
      })

      this.totalData = this.orderPagination.total

      return this.orderPagination.data
    } catch (error) {
      // console.error(error)
    }
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refOrdersTable ? this.$refs.refOrdersTable.localItems.length : 0

    return {
      from: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + (localItemsCount ? 1 : 0),
      to: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + localItemsCount,
      of: this.totalData
    }
  }

  @Watch('paramsPayload', { deep: true, immediate: false })
  private refreshTable () {
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refOrdersTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
