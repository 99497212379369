






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'AdminEditOrderSidebar',
  components: {

  }

})
export default class AdminEditOrderSidebar extends Vue {

}
