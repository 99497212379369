import { render, staticRenderFns } from "./AdminOrdersList.vue?vue&type=template&id=2068a6b1&scoped=true&"
import script from "./AdminOrdersList.vue?vue&type=script&lang=ts&"
export * from "./AdminOrdersList.vue?vue&type=script&lang=ts&"
import style0 from "./AdminOrdersList.vue?vue&type=style&index=0&id=2068a6b1&lang=scss&scoped=true&"
import style1 from "./AdminOrdersList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2068a6b1",
  null
  
)

export default component.exports